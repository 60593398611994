

var popoverTriggerList;
var popoverList;

async function assembleRecordRow(recordData, index) {

	var statusIcon;
	var rowClass;
	var imageName;
	var imageCells;
	var statusMessage;

	if (recordData.file_name != null)
		imageName = recordData.file_name;
	else 
		imageName = "filenotfound";

	const imgMap = await getImage('image', {image: imageName, type: "map", scale: "preview"});
	const imgMapb64 = URL.createObjectURL(imgMap);
	//console.log(imgMapb64)

	const imgPhoto = await getImage('image', {image: imageName, type: "photo", scale: "preview"});
	const imgPhotob64 = URL.createObjectURL(imgPhoto);

	let lat = recordData.lat != null ? recordData.lat : 0; 
	let long = recordData.long != null ? recordData.long : 0; 

	let reportedDate = ERR_MSG_NOT_AVAILABLE_SHORT;
	let address = ERR_MSG_ADDRESS_NOT_FOUND;

	if (recordData.address != null)
		address = recordData.address;

	if (recordData.reported_date == null) {
		statusIcon = `						
			<svg class="icon icon-xl">
				<use xlink:href="vendors/@coreui/icons/svg/free.svg#cil-x"></use>
			</svg>`;
		rowClass = "bg-danger";
		statusMessage = "NO ENVIADA";

		imageCells = `
		<td style="text-align:center">
			<div>
				<img width="100px" height="100px" src="${imgMapb64}"/>
				<br/>
				${address}
				<br/>
				<small>lat ${lat}, long ${long}</small>
			</div>
		</td>
		<td style="text-align:center">
			<div>
				<img width="100px" height="100px" src="${imgPhotob64}"/>
				<br/>
				${reportedDate} 
				<br/>
				<small>${statusIcon}</small>
			</div>
		</td>		
		`;
	}
	else {
		statusIcon = `						
			<svg class="icon icon-xl">
				<use xlink:href="vendors/@coreui/icons/svg/free.svg#cil-check-alt"></use>
			</svg>`;
		rowClass = "bg-success";
		statusMessage = "ENVIADA";
		reportedDate = new Date(parseInt(recordData.reported_date)).toLocaleString("es-MX", {timeZone: "America/Mexico_City"});

		imageCells = `
		<td style="text-align:center">
			<div>
				<a style="cursor:pointer" data-coreui-custom-class="image-large-view-popover" data-coreui-toggle="popover" title="Large View" data-coreui-html="true" 
					data-coreui-content="<img width='600px' height='600px' src='${URL.createObjectURL(await getImage('image', {image: imageName, type: "map", scale: "full"}))}'/>">
					<img style="border: 5px solid #5856d6;" width="100px" height="100px" src="${imgMapb64}"/>
				</a>
				<br/>
				${address}
				<br/>
				<small>lat ${lat}, long ${long}</small>
			</div>
		</td>
		<td style="text-align:center">
			<div>
				<a style="cursor:pointer" data-coreui-custom-class="image-large-view-popover" data-coreui-toggle="popover" title="Large View" data-coreui-html="true" 
					data-coreui-content="<img width='600px' height='600px' src='${URL.createObjectURL(await getImage('image', {image: imageName, type: "photo", scale: "full"}))}'/>">
					<img style="border: 5px solid #5856d6;" width="100px" height="100px" src="${imgPhotob64}"/>
				</a>
				<br/>
				${reportedDate} 
				<br/>
				<small>${statusIcon}</small>
			</div>
		</td>		
		`;
	}


	const newRecordRow = `
	<tr>
		<td>
			<div>
			${new Date(parseInt(recordData.scheduled_date)).toLocaleDateString("es-MX", {timeZone: "America/Mexico_City"})}
			<br/>
			<span class="badge badge-sm ${rowClass} ms-auto">${statusMessage}</span>
			<br/>
			${index}
			</div>
		</td>
		<td>
			<div>
			${recordData.user_name}
			</div>
		</td>
		<td>
			<div>
			<b>${recordData.activity_name}</b>:
			<br/>
			<i>${recordData.comments ? recordData.comments : "No hay info."}</i>
			</div>
		</td>
		${imageCells}
	</tr>
	`;

	return newRecordRow;
}


async function getRecords() {

	try {

		// Check admin
		if (!userInfo.data.is_supervisor) {
			showError(ERR_MSG_SUPERVISOR_ONLY);
			return;
		}

		console.log("getRecords: ");
		let recordsInfo = await getData(`org/records`, {});

		if (recordsInfo.status == "err") {
			showError(recordsInfo.description);
			return;
		}

		const recordsTable = document.getElementById("recordsTable");
		recordsTable.innerHTML = "";

		for (let i = 0; i < recordsInfo.data.length; i++) {

			const newRecordRow = await assembleRecordRow(recordsInfo.data[i], (recordsInfo.data.length - i));
			const newRow = recordsTable.insertRow(recordsTable.rows.length);
			newRow.innerHTML = newRecordRow;
		}

		clearError();

		// Required init for popovers
		popoverTriggerList = document.querySelectorAll('[data-coreui-toggle="popover"]')
		popoverList = [...popoverTriggerList].map(popoverTriggerEl => new coreui.Popover(popoverTriggerEl))

		//popoverList.forEach((elem) => {elem.boundary = document.querySelector("body")});
	
	} catch (err) {
		showError(ERR_MSG_UNEXPECTED + err);
	}

}

async function getStats() {

	try {

		// Check admin
		if (!userInfo.data.is_supervisor) {
			showError(ERR_MSG_SUPERVISOR_ONLY);
			return;
		}

		console.log("getStats: ");
		let statsInfo = await getData(`org/stats`, {});

		if (statsInfo.status == "err") {
			showError(statsInfo.description);
			return;
		}

		const spanUserCount = document.getElementById("spanUserCount");
		const spanActivityCount = document.getElementById("spanActivityCount");
		const spanSentCount = document.getElementById("spanSentCount");
		const spanNotSentCount = document.getElementById("spanNotSentCount");

		spanUserCount.innerText = statsInfo.data.userCount;
		spanActivityCount.innerText = statsInfo.data.activityCount;
		spanSentCount.innerText = statsInfo.data.sentCount;
		spanNotSentCount.innerText = statsInfo.data.notSentCount;

		clearError();
	
	} catch (err) {
		showError(ERR_MSG_UNEXPECTED + err);
	}

} 

async function main() {
	
	await checkUser();
	await getStats();
	await getRecords();
}

main();